import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { colors, getMonthYear } from '../ui'
import styles from './jc-week.module.css'

const StyledChallengeDates = styled.h3`
  color: ${colors.gray};
  margin-bottom: 2rem;
  font-size: 1.8rem;
`

export default function JcWeek({ week }) {
  return (
    <Link
      to={`/zine/${week.number}`}
      key={`/zine/${week.number}`}
      className={styles.recipeLink}
    >
      <div className={styles.feature}>
        <Img
          className={styles.illustration}
          alt={week.title}
          fluid={week.illustration.fluid}
        />
        <div className={styles.desc}>
          <div>
            <div className={styles.label}>{week.region}</div>
          </div>
          <h2 className={styles.recipeTitle}>
            Issue #{week.number}:
            <br />
            {week.title}
          </h2>
          <StyledChallengeDates>{getMonthYear(week)}</StyledChallengeDates>
          <ul className={styles.recipeList}>
            {week.recipes.map(r => (
              <li className={styles.listItem} key={r.title}>
                {r.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Link>
  )
}
