import { DiscussionEmbed } from 'disqus-react'
import React from 'react'

export const Disqus = ({ identifier, title, width }) => {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier, title },
  }

  return (
    <div
      style={{
        maxWidth: width || '800px',
        margin: '100px auto 0 auto',
        padding: '0 24px',
      }}
    >
      <DiscussionEmbed {...disqusConfig} />
    </div>
  )
}

export default Disqus
